import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await fetch('/auth/user', {
        method: 'GET',
        credentials: 'include' // Equivalent to axios's withCredentials: true
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const handleLogin = () => {
    window.location.href = '/auth/login';
  };

  const fetchDriveFiles = async () => {
    try {
      const response = await fetch('/api/drive/files', {
        method: 'GET',
        credentials: 'include' // Equivalent to axios's withCredentials: true
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setFiles(data.files);
    } catch (error) {
      console.error('Error fetching Drive files:', error);
    }
  };
  
  return (
    <div className="App">
      {user ? (
        <>
          <p>Welcome, {user.name}!</p>
          <button onClick={fetchDriveFiles}>Fetch Drive Files</button>
          <ul>
            {files.map(file => (
              <li key={file.id}>{file.name}</li>
            ))}
          </ul>
        </>
      ) : (
        <button onClick={handleLogin}>Login with Google</button>
      )}
      <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
